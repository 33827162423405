<template>
  <v-container fluid>
    <Header headerClass="politiche" title="Politiche per l'aria" />
    <v-container page>
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Piano Regionale di Qualità dell’Aria</h2>
          <div class="w-80">
            <p class="mt-40">
              Il Piano Regionale di Qualità dell’Aria è lo
              <strong>strumento di pianificazione e programmazione</strong> di
              Regione Piemonte sulla qualità dell’aria.
            </p>
            <p class="mt-40">
              Sin dal <strong>2000</strong>, la Regione Piemonte si è dotata di
              una Legge regionale di Piano per la Tutela e il Risanamento della
              Qualità dell’Aria (L.R. 43/2000), articolata per piani stralcio
              relativi a singoli comparti di applicazione (trasporti,
              riscaldamento, industria). Questo ha messo in campo numerose
              misure che hanno contribuito al miglioramento della qualità
              dell’aria.
            </p>
          </div>
          <v-card flat>
            <v-list-item three-line class="center">
              <v-list-item-content>
                <p class="text-center">
                  A <strong>marzo del 2019</strong> è stato approvato il nuovo
                  Piano Regionale di Qualità dell’Aria (PRQA) con orizzonte
                  temporale al 2030 e con l’obiettivo di coordinare e
                  indirizzare tutte le politiche regionali che influenzano la
                  qualità dell’aria.
                </p>
                <p class="text-center">
                  <a
                    class="logo-pqra"
                    target="_blank"
                    href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/piano-regionale-qualita-dellaria-prqa"
                  >
                    <span class="hidden">Logo PRQA</span>
                  </a>
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="w-80">
            <p class="mt-40">
              La finalità è realizzare un sistema di misure specifiche che
              permetta:
            </p>
            <div class="lista">
              <ul>
                <li>
                  di far rientrare nel più breve tempo possibile, anche in
                  sinergia con le misure nazionali, i valori degli inquinanti
                  che ad oggi superano i limiti - su tutto il territorio
                  regionale o in alcune zone/agglomerati
                </li>
                <li>
                  di preservare la qualità dell’aria nelle zone in cui i livelli
                  degli inquinanti siano stabilmente al di sotto dei valori
                  limite, mantenendone e/o riducendone ulteriormente le
                  concentrazioni.
                </li>
              </ul>
            </div>
          </div>
          <v-container fluid class="prqa mt-40">
            <v-row>
              <v-col class="col-md-4 col-12">
                <router-link
                  class="prqa-trasporti"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'trasporti' }
                  }"
                >
                  <div class="text-right">
                    <p>TRASPORTI</p>
                  </div>
                </router-link>
              </v-col>
              <v-col class="col-md-8 col-12">
                <router-link
                  class="prqa-energia"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'energia' }
                  }"
                >
                  <div class="text-right">
                    <p>ENERGIA</p>
                  </div>
                </router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-7 col-12">
                <router-link
                  class="prqa-industria"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'industria' }
                  }"
                >
                  <div class="text-right">
                    <p>INDUSTRIA</p>
                  </div>
                </router-link>
              </v-col>
              <v-col class="col-md-5 col-12">
                <router-link
                  class="prqa-riqualificazione"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'riqualificazione' }
                  }"
                >
                  <div class="text-right">
                    <p>RIQUALIFICAZIONE URBANA</p>
                  </div>
                </router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-md-5 col-12">
                <router-link
                  class="prqa-agricoltura"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'agricoltura' }
                  }"
                >
                  <div class="text-right">
                    <p>AGRICOLTURA</p>
                  </div>
                </router-link>
              </v-col>
              <v-col class="col-md-7 col-12">
                <router-link
                  class="prqa-comunicazione"
                  :to="{
                    name: 'misura',
                    params: { anchor: 'comunicazione' }
                  }"
                >
                  <div class="text-right">
                    <p>COMUNICAZIONE</p>
                  </div>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
          <div class="w-80">
            <p class="mt-40">
              Il nuovo Piano si ispira agli obiettivi di sostenibilità
              ambientale delle più recenti strategie e politiche comunitarie,
              nazionali e regionali, ed è declinato in
              <strong>47 specifiche Misure</strong>. Queste indicano soluzioni
              realistiche, strategie di lungo periodo e azioni possibili a breve
              termine. Hanno come
              <strong
                >comune denominatore il miglioramento della qualità
                dell’aria</strong
              >
              per la protezione della salute umana e dell’ecosistema.
            </p>
          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuPolitiche page="piano-regionale" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuPolitiche from "@/views/politiche/MenuPolitiche";

export default {
  components: {
    Header,
    MenuPolitiche
  }
};
</script>
